/* eslint-disable import/prefer-default-export */
import {stripNonNumeric} from 'utils';

/**
 * @param {String} value Phone number
 * @param {String} ddi DDI code (e.g. 55, 1...)
 * @returns {String} Formatted phone number
 */
export function formatPhone(value, ddi) {
	// Aux
	let maskedValue;
	const pureValue = stripNonNumeric(value);
	// If ddi and not from brazil
	if (ddi && ddi !== '55') {
		maskedValue = '+' + pureValue.substr(0, ddi.length) + ' ' + pureValue.substr(ddi.length, pureValue.length);
		return maskedValue;
	}
	// If brazil
	if (pureValue.length === 10) {
		maskedValue = pureValue.substr(0, 2) + ' ' + pureValue.substr(2, 4) + '-' + pureValue.substr(6, 4);
	} else if (pureValue.length === 11) {
		maskedValue = pureValue.substr(0, 2) + ' ' + pureValue.substr(2, 5) + '-' + pureValue.substr(7, 4);
	} else if (pureValue.length === 12) {
		maskedValue =
			'+' +
			pureValue.substr(0, 2) +
			' ' +
			pureValue.substr(2, 2) +
			' ' +
			pureValue.substr(4, 4) +
			'-' +
			pureValue.substr(8, 4);
	} else if (pureValue.length === 13) {
		maskedValue =
			'+' +
			pureValue.substr(0, 2) +
			' ' +
			pureValue.substr(2, 2) +
			' ' +
			pureValue.substr(4, 5) +
			'-' +
			pureValue.substr(9, 4);
	} else {
		maskedValue = value;
	}
	return maskedValue;
}

/**
 * @param {String} word Any string wou want to mask
 * @param {Number?} minShow Number of min chars you still want to show
 * @returns {String} Formatted word
 */
export function mask(word, minShow) {
	if (!word) return '';
	word = word.toString();
	return `${word.substring(0, minShow || 4)}${word.substring(minShow || 4, minShow || 4).replace(/./g, '*')}`;
}

export function checkPhoneHiddenAndFormat(value, fieldConfig, ddi) {
	if (!fieldConfig || !value) return ' ';

	if (fieldConfig.find(i => i.key === 'phoneWhatsapp' && i.isHidden)) {
		return mask(value);
	}
	return formatPhone(value, ddi);
}
