import {DataLayer} from '@nimbus-target/ui';
import PermissionToggle from 'components/permission/PermissionToggle';
import useIsAllowed from 'components/permission/useIsAllowed';
import {useThemeContext} from 'context';
import {useUserContext} from 'context/index';
import {Fragment, useEffect, useState} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {useAppValue, useDispatchSetAppValue} from 'redux/hooks/app';
import {useDispatchGet, useDispatchPost} from 'redux/hooks/fetch';

/* MATERIAL */
import {Badge, Button} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles} from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import AddCircle from '@material-ui/icons/AddCircle';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import ContactSupport from '@material-ui/icons/ContactSupportOutlined';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import MenuIcon from '@material-ui/icons/Menu';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import SettingsIcon from '@material-ui/icons/Settings';
import Skeleton from '@material-ui/lab/Skeleton';

import RegularDropdown from 'components/Dropdown';
import IconButton from 'components/buttons/IconButton';
import Drawer from 'components/drawer/Drawer';
import Dropdown from 'components/dropdown/Dropdown';

import ChartsMenuDropdown from './ChartsMenuDropdown';
import PerfilBar from './PerfilBar';
// import NotificationsButton from './Notifications.Button';
import AlertsButton from './Alerts.Button';
import UsersOnlineButton from './UsersOnlineButton';
import UsersTaskButton from './UsersTaskButton';
// import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import FeatureToggle from 'components/FeatureToggle';
import VoIpHeader from 'components/VoIpHeader';
import NotificationPopups from 'routes/notification-center/NotificationPopups';
import SurveyModal from 'routes/notification-center/SurveyModal';
import NotificationAcceptModal from 'routes/notification-center/notificationAcceptModal';
import UserTermsAcceptModal from 'routes/terms-user/UserTermsAcceptModal';
import FavoriteLeads from './FavoriteLeads';
import FavoriteLeadsButton from './FavoriteLeadsButton';
import NetworkCondition from './NetworkCondition';
import PocketOfLeadsButton from './PocketOfLeadsButton';
import UsersOnline from './UsersOnline';
import UsersTask from './UsersTask';

function gtag(...rest) {
	DataLayer.push(...rest.slice(1));
}

const useStyles = makeStyles(theme => {
	return {
		grow: {
			flexGrow: 1,
		},
		homeLink: {
			width: 130,
		},
		logo: {
			height: 34,
			display: props => (props.loadLogo ? 'block' : 'none'),
		},
		appBar: {
			'display': props => (props.voipEmbedded ? 'none' : 'block'),
			'cursor': props => (props.VoipIsActive ? 'not-allowed' : 'initial'),
			'height': theme.heights.header,
			'backgroundColor': theme.palette.supportThree,
			'& > div': {
				height: '100%',
				pointerEvents: props => (props.VoipIsActive ? 'none' : 'initial'),
			},
		},
		menuButton: {
			marginRight: theme.spacing(2),
		},
		title: {
			display: 'none',
			[theme.breakpoints.up('sm')]: {
				display: 'block',
			},
		},
		menu: {
			'display': 'flex',
			'alignItems': 'center',
			'marginRight': theme.spacing(7),
			'marginLeft': theme.spacing(7),
			[theme.breakpoints.down('1100')]: {
				marginRight: theme.spacing(3),
				marginLeft: theme.spacing(3),
			},
			'flexGrow': 1,
			'& > *': {
				marginRight: 35,
				[theme.breakpoints.down('1580')]: {
					marginRight: 17,
				},
				[theme.breakpoints.down('1460')]: {
					marginRight: 17,
				},
				[theme.breakpoints.down('1380')]: {
					marginRight: 0,
				},
				[theme.breakpoints.down('1270')]: {
					marginRight: 0,
				},
			},
		},
		link: {
			color: theme.palette.supportTwo,
		},
		icon: {
			fontSize: 26,
			[theme.breakpoints.down('1300')]: {
				fontSize: 20,
			},
			[theme.breakpoints.down('1100')]: {
				fontSize: 18,
			},
		},
		sectionDesktop: {
			'display': 'none',
			'flexGrow': 1,
			[theme.breakpoints.up('1000')]: {
				display: 'flex',
				alignItems: 'center',
			},
			'& button, a': {
				color: theme.palette.supportOne,
			},
		},
		textButton: {
			fontFamily: 'Montserrat',
			textTransform: 'capitalize',
			pointerEvents: props => (props.VoipIsActive ? 'none' : 'initial'),
			fontSize: 14,
			[theme.breakpoints.down('1300')]: {
				fontSize: 12,
			},
		},
		clickableButton: {
			pointerEvents: props => (props.VoipIsActive ? 'none' : 'initial'),
		},
		moreIconsButtons: {
			'pointerEvents': props => (props.VoipIsActive ? 'none' : 'initial'),
			'color': 'rgba(0,0,0,0.6) !important',
			'fontSize': '1.25rem',
			'& span': {
				'& svg': {
					marginRight: 10,
				},
			},
		},
		sectionMobile: {
			'width': '100%',
			'display': 'flex',
			'alignItems': 'center',
			[theme.breakpoints.up('1000')]: {
				display: 'none',
			},
			'& > button': {
				color: theme.palette.supportOne,
				marginRight: theme.spacing(2),
			},
			'& :nth-child(3)': {
				marginLeft: 'auto',
				marginRight: 0,
			},
		},
		leadData: {
			'margin': 'auto',
			'color': theme.palette.supportOne,
			'textAlign': 'center',
			'width': '70%',
			'& > div:nth-child(1)': {
				fontSize: 17,
				fontWeight: 'bold',
				whiteSpace: 'nowrap',
				textOverflow: 'ellipsis',
				overflow: 'hidden',
			},
		},
		middleSectionTitle: {
			color: theme.palette.supportOne,
			width: '100%',
			textAlign: 'center',
		},
		moreIconsDropdown: {
			'marginLeft': 10,
			'& > button': {
				border: 'none',
				borderRadius: '50%',
				padding: 0,
			},
			'& > div': {
				overflow: 'hidden',
				zIndex: 9999,
			},
		},
		newButton: {
			'& > span': {
				backgroundColor: '#e5c338',
			},
		},
	};
});

function Header() {
	const VoipIsActive = useAppValue('VOIP_isActiveComponent');

	const {pathname} = useLocation();
	const voipEmbedded = pathname.includes('/webphone');

	const {
		clientId,
		isAdmin: userIsAdmin,
		groupType: userGroupType,
		manager: userManager,
		groupId,
		canImportLeadsExcel,
		canAddPDVWhatsapp,
		_id: userId,
	} = useUserContext();

	const setClient = useDispatchSetAppValue('clientData');
	const client = useAppValue('clientData');

	const usersOnline = useAppValue('usersOnline');

	const isUserAllowedToRedistribute = useIsAllowed('redistribution');
	const canSeeUsersScale = useIsAllowed('canSeeUsersScale');

	const {_id, name, statusDescription} = useAppValue('selectedLeadData');

	const {isMobile} = useThemeContext();
	const mobileScreen = useAppValue('mobileScreen');
	const setMobileScreen = useDispatchSetAppValue('mobileScreen');

	const [loadLogo, setLoadLogo] = useState(false);

	const drawerPerfil = useAppValue('perfilDrawer');
	const setDrawerPerfil = useDispatchSetAppValue('perfilDrawer');

	const tasksDrawer = useAppValue('tasksDrawer');
	const setTasksDrawer = useDispatchSetAppValue('tasksDrawer');

	const onlineUsersDrawer = useAppValue('onlineUsersDrawer');
	const setOnlineUsersDrawer = useDispatchSetAppValue('onlineUsersDrawer');

	const favoriteLeadsDrawer = useAppValue('favoriteLeadsDrawer');
	const setFavoriteLeadsDrawer = useDispatchSetAppValue('favoriteLeadsDrawer');

	const [sellerAdminList, setSellerAdminList] = useState([]);

	const [kanbanLeadList, setKanbanLeadList] = useState([]);

	const [whatsappModal, setWhatsappModal] = useState(false);
	const setIsWhatsappPerUserModalOpen = useDispatchSetAppValue('isWhatsappPerUserModalOpen');
	const setWhatsappPerUserModalContext = useDispatchSetAppValue('whatsappPerUserModalContext');

	const featureToggleList = useAppValue('featureToggleList');

	const post = useDispatchPost();
	const get = useDispatchGet();

	const openSurvey = useAppValue('openSurvey');

	const supportMailbox = useAppValue('supportMailbox');
	const handleWhatsapp = () => {
		setIsWhatsappPerUserModalOpen(true);
		setWhatsappPerUserModalContext('not-requested');
	};

	useEffect(() => {
		const isWhatsappLightPerUserActive = featureToggleList.some(
			feature => feature.featureId.key === 'whatsappLightPerUser',
		);
		console.log({alo: isWhatsappLightPerUserActive});
		// If feature is not active, ignore
		if (!isWhatsappLightPerUserActive) {
			return;
		}

		console.log({alous: isWhatsappLightPerUserActive});

		get('/whatsapp-sender/check-my-phone-status')
			.then(responseData => {
				const canSeeButton = responseData.data?.phoneStatus;
				if (canSeeButton !== 'active') {
					setWhatsappModal(true);
				}
			})
			.catch(err => {
				console.error(err);
				if (err?.showButton) {
					setWhatsappModal(true);
				}
				setWhatsappModal(false);
			});
	}, [get, featureToggleList, setWhatsappModal, userIsAdmin, userId, userManager]);

	useEffect(() => {
		post('/pipeline/list').then(responseData => {
			let pipelineList = responseData.data.map(pipe => {
				return {label: pipe.name, link: `/kanban-lead-list?pipeline=${pipe._id}`};
			});

			setKanbanLeadList(pipelineList);
		});
	}, [post]);

	useEffect(() => {
		if (!clientId) return; // TODO: tirar isso quando o contexto só atualizar uma vez
		post('/client/view', {id: clientId}).then(responseData => {
			setClient(responseData.data);
			gtag('set', 'user_properties', {
				tenantId: responseData.data._id,
				tenant: responseData.data.name,
			});
		});
	}, [clientId, post, setClient]);

	useEffect(() => {
		let aux = [];
		if (isUserAllowedToRedistribute) {
			aux.push({
				label: 'Redistribuição de Leads',
				link: '/redistribution/list',
				disabled: !isUserAllowedToRedistribute,
			});
		}
		if (canImportLeadsExcel) {
			aux.push({
				label: 'Importação - planilha',
				link: '/import',
				disabled: !canImportLeadsExcel,
			});
		}

		if (canSeeUsersScale) {
			aux.push({
				label: 'Escala de Atendimento',
				link: '/timetable/list',
				disabled: !canSeeUsersScale,
			});
		}
		setSellerAdminList(aux);
	}, [canImportLeadsExcel, isUserAllowedToRedistribute, canSeeUsersScale]);

	const classes = useStyles({
		loadLogo,
		voipEmbedded,
		VoipIsActive,
	});

	const settingsList = [
		{
			label: 'Configurações',
			link: '/settings',
			disabled: !useIsAllowed('settings'),
		},
		{
			label: 'Integrações',
			link: '/integration/list',
			disabled: !useIsAllowed('integration'),
		},
		{
			label: 'MegaDash',
			link: '/megadash/list',
			disabled: !useIsAllowed('settingsRestricted'),
		},
		{label: 'Hubs', link: '/hub/list', disabled: !useIsAllowed('hub')},
		{label: 'Grupos', link: '/group/list', disabled: !useIsAllowed('group')},
		{label: "PDV's", link: '/store/list', disabled: !useIsAllowed('hub')},
		{label: 'Equipes', link: '/team/list', disabled: !useIsAllowed('teams')},
		{
			label: 'Usuários',
			link: '/user/list',
			disabled: !useIsAllowed('setupMenu'),
		},
	];

	const adminList = [
		{
			label: 'Indicadores de Uso',
			link: '/platform-indicators',
			disabled: !useIsAllowed('indicators'),
		},
		{
			label: 'Importação - Planilha',
			link: '/import',
			disabled: !useIsAllowed('leadImport'),
		},
		{
			label: 'Atualizar Leads - planilha',
			link: '/lead/update',
			disabled: !useIsAllowed('leadImport'),
		},
		{
			label: 'Treinamentos',
			link: '/training-admin/list',
			disabled: !useIsAllowed('trainingAdmin'),
		},
		{
			label: 'Central de Tarefas e Notas',
			link: '/task/create-multiple',
			disabled: !useIsAllowed('hub'),
		},
		{
			label: 'Base de Conhecimento',
			link: '/article/list',
			disabled: !useIsAllowed('hub'),
		},
		{
			label: 'Central de Notificações',
			link: '/notification-center/list',
			disabled: !useIsAllowed('store'),
		},
		{
			label: 'Escala de Atendimento',
			link: '/timetable/list',
			disabled: !useIsAllowed('canSeeUsersScale'),
		},
		{
			label: 'Redistribuição de Leads',
			link: '/redistribution/list',
			disabled: !useIsAllowed('redistribution'),
		},
		{
			label: 'Importações de Leads',
			link: '/import/list',
			disabled: !useIsAllowed('leadImport'),
		},
		{
			label: 'Remoção de Leads',
			link: '/delete-leads/list',
			disabled: !useIsAllowed('nimbusAdmin'),
		},
		{
			label: 'Uso de canais de comunicação',
			link: '/channel-use/',
			disabled: !useIsAllowed('nimbusAdmin'),
		},
		{
			label: 'Pesquisa com usuário',
			link: '/survey/list/',
			disabled: !useIsAllowed('hub'),
		},
	];

	const campaignList = [
		{
			label: 'Minhas campanhas',
			link: '/campaign/list',
			disabled: !useIsAllowed('campaign'),
		},
		{
			label: 'Meus templates de e-mail',
			link: '/campaign/email-template/',
			disabled: !useIsAllowed('campaign'),
		},
		{
			label: 'Meus templates de Whatsapp',
			link: '/campaign/whatsapp-template/',
			disabled: !useIsAllowed('campaign'),
		},
		{
			label: 'Meus e-mails cadastrados',
			link: '/campaign/email-senders/',
			disabled: !useIsAllowed('campaign'),
		},
	];

	const handlerDrawer = isToShow => {
		if (VoipIsActive) return false;

		setDrawerPerfil(isToShow);
		gtag('event', 'open_perfil');
	};

	if (
		pathname.indexOf('login') !== -1 ||
		pathname.indexOf('password-recover') !== -1 ||
		pathname.indexOf('privacy') !== -1 ||
		pathname.indexOf('embed-mobile-dash') !== -1
	)
		return null;

	return (
		<Fragment>
			<NetworkCondition />
			<AppBar position='static' className={classes.appBar}>
				<Toolbar>
					<div className={classes.sectionDesktop}>
						<Link className={classes.homeLink} to='/'>
							{!loadLogo && <Skeleton variant='rect' width={133} height={40} />}
							<img src={client && client.logo} className={classes.logo} onLoad={() => setLoadLogo(true)} alt='logo' />
						</Link>
						<div className={classes.menu}>
							<Button className={classes.textButton} data-tour='tour_home_menu_home'>
								<Link to='/'>Home</Link>
							</Button>
							{/* if the kanban leadlist feature is allowed, show 'Leads' in the header, otherwise show training */}
							{featureToggleList.find(data => data.featureId.key === 'kanbanLeadList') && (
								<Dropdown
									hasArrow={true}
									label='Funil'
									className={classes.textButton}
									itensList={kanbanLeadList}
									excludeDivider={true}
									placement='bottom-start'
									hideScroll={true}
									internalPadding={10}
									forceReload={true}
								/>
							)}
							<FeatureToggle configKey='entityAccount'>
								<Button className={classes.textButton}>
									<Link to='/account-management/list'>Contas</Link>
								</Button>
							</FeatureToggle>
							<FeatureToggle configKey='entityRegistry'>
								<PermissionToggle permissionKey='hub' hasFeedback={false}>
									<Button className={classes.textButton}>
										<Link to='/registry/list'>Cadastros</Link>
									</Button>
								</PermissionToggle>
							</FeatureToggle>
							<Button className={classes.textButton} data-tour='tour_home_menu_atendimento'>
								<Link to='/chat'>Atendimento</Link>
							</Button>
							<ChartsMenuDropdown />
							<PermissionToggle permissionKey='store' hasFeedback={false}>
								<Dropdown
									hasArrow={true}
									label='Admin'
									className={classes.textButton}
									itensList={adminList}
									dataTour='tour_home_menu_admin'
								/>
							</PermissionToggle>
							{/** For users who are not admin, but can redistribute leads or import leads */}
							{!userIsAdmin && sellerAdminList && sellerAdminList.length > 0 && (
								<Dropdown
									hasArrow={true}
									label='Admin'
									className={classes.textButton}
									itensList={sellerAdminList}
									dataTour='tour_home_menu_admin'
								/>
							)}
							<FeatureToggle configKey='newTasksPannel'>
								<Button className={classes.textButton}>
									<Link to='/tasks'>Tarefas</Link>
								</Button>
							</FeatureToggle>
							{/* if the kanban leadlist feature is allowed, show 'Leads' in the header, otherwise show training */}
							{!featureToggleList.find(data => data.featureId.key === 'kanbanLeadList') && (
								<Button className={classes.textButton}>
									<Link to='/training/list'>Treinamentos</Link>
								</Button>
							)}
							<FeatureToggle configKey='campaignModule'>
								<PermissionToggle permissionKey='campaign' hasFeedback={false}>
									<Badge badgeContent={'Beta'} color='primary' overlap='rectangular'>
										<Dropdown
											hasArrow={true}
											label='Campanhas'
											className={classes.textButton}
											itensList={campaignList}
										/>
									</Badge>
								</PermissionToggle>
							</FeatureToggle>
						</div>
						<VoIpHeader />

						<FeatureToggle configKey='pocketOfLeads'>
							{/** TODO: I know it`s ugly, but given our current component of PermissionToggle, i had to duplicate things here... Improve later */}
							<PermissionToggle permissionKey='pocketOfLeadsForAdmin' hasFeedback={false}>
								<PocketOfLeadsButton />
							</PermissionToggle>
							<PermissionToggle permissionKey='pocketOfLeadsForStore' hasFeedback={false}>
								<PocketOfLeadsButton />
							</PermissionToggle>
						</FeatureToggle>
						<FeatureToggle configKey='whatsappLightPerUser'>
							{whatsappModal && (
								<IconButton alt='Parear o WhatsApp' handleClick={() => handleWhatsapp()}>
									<WhatsAppIcon />
								</IconButton>
							)}
						</FeatureToggle>
						<PermissionToggle permissionKey='setupMenu' hasFeedback={false}>
							<Dropdown
								className={classes.clickableButton}
								label={<SettingsIcon className={classes.icon} data-tour='tour_home_menu_config' />}
								itensList={settingsList}
							/>
						</PermissionToggle>

						<AlertsButton />

						<RegularDropdown
							className={classes.moreIconsDropdown}
							hasArrow={false}
							label={
								<Box display='flex' alignItems='center' justifyItems='center' px={0} py={1}>
									<AddCircle />
								</Box>
							}
							positionX='right'
							autoClosing={false}
						>
							{() => {
								return (
									<div>
										<div>
											<IconButton
												className={classes.moreIconsButtons}
												alt='Perfil'
												handleClick={() => handlerDrawer(true)}
												data-tour='tour_home_menu_perfil'
												style={{borderRadius: 0}}
											>
												<AccountCircle className={classes.icon} />
												Perfil
											</IconButton>
											<hr />
										</div>
										<div>
											<UsersTaskButton drawer={tasksDrawer} setDrawer={setTasksDrawer} />
										</div>
										<div>
											<UsersOnlineButton drawer={onlineUsersDrawer} setDrawer={setOnlineUsersDrawer} />
										</div>
										<div>
											<FavoriteLeadsButton drawer={favoriteLeadsDrawer} setDrawer={setFavoriteLeadsDrawer} />
										</div>
										{userGroupType === 'store' && userManager === 'store' && canAddPDVWhatsapp && (
											<div>
												<IconButton
													className={classes.moreIconsButtons}
													handleClick={() => {
														window.location.href = `/store/update/${groupId}`;
													}}
												>
													<AddCircle className={classes.icon} />
													Configurações do PDV
												</IconButton>
											</div>
										)}
									</div>
								);
							}}
						</RegularDropdown>

						<PermissionToggle permissionKey='canOpenSupportTicket' hasFeedback={false}>
							<FeatureToggle configKey='botSupportModule'>
								<Badge
									badgeContent={supportMailbox ? `+${supportMailbox}` : null}
									overlap='circular'
									className={classes.newButton}
								>
									<IconButton
										alt='Suporte'
										handleClick={() => {
											window['myLandbot'].open();
											document.getElementById('landbot-styles').innerHTML = '';
										}}
										data-tour='menu_support'
									>
										<ContactSupport />
									</IconButton>
								</Badge>
							</FeatureToggle>
						</PermissionToggle>

						{/* perfil drawer */}
						<Drawer
							title='Perfil'
							direction={isMobile ? 'left' : 'right'}
							isOpen={drawerPerfil}
							onClose={() => handlerDrawer(false)}
						>
							<PerfilBar handleClose={() => handlerDrawer(false)} />
						</Drawer>
						{/* tasks drawer */}
						<Drawer title={`Tarefas`} direction={'right'} isOpen={tasksDrawer} onClose={() => setTasksDrawer(false)}>
							<UsersTask onClose={() => setTasksDrawer(false)} />
						</Drawer>
						{/* online users drawer */}
						<Drawer
							title={`Usuários Online (${usersOnline.length})`}
							direction={'right'}
							isOpen={onlineUsersDrawer}
							onClose={() => setOnlineUsersDrawer(false)}
						>
							<UsersOnline onClose={() => onlineUsersDrawer(false)} />
						</Drawer>
						{/* favorite leads drawer */}
						<Drawer
							title={`Leads favoritos`}
							direction={'right'}
							isOpen={favoriteLeadsDrawer}
							onClose={() => setFavoriteLeadsDrawer(false)}
						>
							<FavoriteLeads onClose={() => favoriteLeadsDrawer(false)} />
						</Drawer>
					</div>
					<div className={classes.sectionMobile}>
						{mobileScreen === 'left' && (
							<Fragment>
								<IconButton handleClick={() => handlerDrawer(true)}>
									<MenuIcon />
								</IconButton>
								<Link className={classes.homeLink} to='/chat'>
									{!loadLogo && <Skeleton variant='rect' width={133} height={40} />}
									<img
										src={client && client.logo}
										className={classes.logo}
										onLoad={() => setLoadLogo(true)}
										alt='logo'
									/>
								</Link>
								<AlertsButton />
							</Fragment>
						)}
						{mobileScreen === 'right' && (
							<Fragment>
								<IconButton handleClick={() => setMobileScreen('left')}>
									<KeyboardBackspaceIcon />
								</IconButton>
								<div className={classes.leadData}>
									<div>{name}</div>
									<div>{statusDescription}</div>
								</div>
								<IconButton
									handleClick={() => {
										setMobileScreen('middle');
										window.history.pushState(null, document.title, `${window.location.origin}/chat/${_id}`);
									}}
								>
									<MoreHorizIcon />
								</IconButton>
							</Fragment>
						)}
						{mobileScreen === 'middle' && (
							<Fragment>
								<IconButton handleClick={() => setMobileScreen('right')}>
									<KeyboardBackspaceIcon />
								</IconButton>
								<div className={classes.middleSectionTitle}>Informações do Lead</div>
							</Fragment>
						)}
					</div>
					<UserTermsAcceptModal />
					<NotificationAcceptModal />
					<NotificationPopups />
					<SurveyModal />
				</Toolbar>
			</AppBar>
		</Fragment>
	);
}

export default Header;
